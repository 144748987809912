<template>
    <div>
    <section class="breadcrumb-outer text-center bg-orange">
        <div class="container">
                <h2>{{ $t('Header.PressService.Photo') }}</h2>
        </div>
    </section>
    <photo class="white"></photo>
    </div>
</template>
<script>
import Photo from '../components/Photo'
export default {
    name: "Photos",
    components:{
        Photo
    }
}
</script>
<style scoped>
.bg-orange{
    margin: 0;
    background-color: #3498DB !important;
    background-image: none;
}
section {
    padding: 40px 0 !important;
    position: relative;
}
.breadcrumb-outer h2{
    padding: 70px 0 5px !important;
}
@media(max-width: 400px){
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
</style>