<template>
  <div>
    <!-- <loader2 v-if="load == true"></loader2>     -->
    <div class="wrapper">
      <div style="padding-top: 4%" class="container">
        <div class="row">
          <div
            v-for="(image, i) in posts1"
            :key="i"
            class="col-md-4"
            style="margin-bottom: 20px"
          >
            <div class="box lazyload blur-up">
              <div data-aos="zoom-in-up" class="content">
                <img
                  class="lazyload blur-up"
                  :data-src="
                    'https://backend.scocenter.uz/storage/' + image.image
                  "
                  alt="rvrv"
                  width="100%"
                  height="100%"
                />
                <br />
              </div>
              <div class="content-hover">
                <p class="text-center white">
                  {{ image[`name_${$i18n.locale}`] }}
                </p>
                <button class="btn">
                  <router-link
                    :to="'/photo-detail/' + image.id"
                    style="color: white"
                    >{{ $t("Main.korish") }}
                  </router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../apis";
export default {
  name: "",
  data() {
    return {
      posts1: "",
      load: true,
    };
  },
  methods: {
    async makeRequest() {
      try {
        const res = await api.get("/gcategory").then((response) => {
          setTimeout(() => (this.load = false), 500);
          this.posts1 = response.data.ggallery;
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    this.makeRequest();
  },
};
</script>
<style scoped>
p {
  font-size: 17px;
  font-weight: 600;
}
.card {
  width: 100% !important;
  height: 100% !important;
}
.wrapper {
  background: white !important;
  margin-bottom: 10px;
}
.btn {
  /* background: #3498DB; */
  color: white;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 17px;
  border-radius: 64px !important;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: white;
  font-weight: 600;
}
.box:hover {
  filter: grayscale(100%);
  transition: all ease-in-out 0.5s;
}
</style>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Patua+One");
body {
  text-align: center;
  .container {
    padding-top: 1%;
    padding-bottom: 4%;
  }

  .box {
    position: relative;
    max-height: 255px;
    height: 255px;
    background-color: white;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;

    .content-hover {
      transform: scale(0, 0);
      position: absolute;
      height: 100%;
      // width: 100%;
      opacity: 1 black;
      // box-shadow: inset 0 0 0 15px #1B1B1B;
      transition: all 0.2s ease-in-out;
      display: flex;
      justify-content: center;
      flex-flow: column;
      padding: 2em 2em;

      h1 {
        color: #ffffff;
        font-size: 1.5em;
        text-transform: uppercase;
        font-family: "Patua One", cursive;
        font-style: italic;
      }

      p {
        color: #ffffff;
      }
    }

    .content {
      display: flex;
      justify-content: center;
      // flex-flow: column;
      border: 5px solid #3498db;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }

      p {
        font-size: 1.2em;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: -2em;
      }
    }

    &:hover > {
      .content-hover,
      .content-hover2 {
        transform: scale(1, 1);
        cursor: pointer;
      }
    }
  }
  .box-tech {
    position: relative;
    max-height: 255px;
    height: 255px;
    background-color: #eeeeee;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    padding: 2em;

    .content {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-flow: column;
      padding-top: 5em;

      p {
        font-size: 1.2em;
        font-weight: 700;
        color: #8e8e8e;
        text-transform: uppercase;
        margin-top: 0em;
        transition: all 0.2s ease-in-out;
      }

      .desc {
        transform: scale(0, 0);
        transition: all 0.2s ease-in-out;

        .line {
          border-top: 1px solid #000000;
          width: 0px;
          margin-top: 0em;
          margin-bottom: 1em;

          transition: all 0.2s ease-in-out;
        }
        p {
          font-size: 1em !important;
          font-weight: 600;
          text-transform: none;
          margin-top: 0em;
        }
      }
    }

    &:hover {
      .content {
        padding-top: 0em;
        cursor: pointer;
        opacity: 0.5;

        img {
          width: 60px;
          filter: none;
        }

        p {
          color: #111111;
        }

        .desc {
          display: block !important;
          transform: scale(1, 1);

          .line {
            width: 50px;
          }
        }
      }
    }
  }
}
</style>